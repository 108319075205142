
//(function () {

    // Init des sliders
    const slidersAncre = document.querySelectorAll('.js-slider-ancres');
    let sliderAncre;        // instance de splide utililisé plus loin dans la lib th_ancres.js
    slidersAncre.forEach(function(slider) {
        sliderAncre = initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            wheel: true,
            releaseWheel: true,
            autoHeight: true,
            pagination: false,
            arrows: true,
            breakpoints: {
                980: {
                    direction: 'ltr',
                    arrows: true
                },
            }         // espace pour les flèches de navigation
        }).mount();
        return splide;
    }
//})();
